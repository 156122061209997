import LazyIframe from "components/common/iframe/lazyIframe";
import LayoutStep from "components/common/layout/layoutStep";
// import Button from "components/ui/atoms/button/button";
import { titleTrainingDays } from "data/config";
import Link from "gatsby-link";
import React from "react";

import SEO from "../../components/SEO/SEO";

const Step8 = () => {
  return (
    <LayoutStep>
      <SEO
        title={`Life in the company | ${titleTrainingDays}`}
        description={"Moving video about how Ramon y Cajal high school changed Dani´s life."}
        canonical={"training-days/step-8/"}
      />
      <div className="container step">
        <p className={"h1"}>{titleTrainingDays} - Step 8</p>
        <h1>Life in the company</h1>
        <section className="videoCart">
          <main className={"slideLeft s05"}>
            <div className={"video--bloque--a"}></div>
            <LazyIframe src={"oBcuXX6QSJw"} title={"Life in thse company"} />
          </main>
          <aside className={"slideLeft s1"}>
            <div className="stepNavigation">
              <Link to="/training-days/step-7/#top" className={"stepNavigation__button--back "}>
                {" "}
                <span className={"stepNavigation__button--back--right"}> </span> Back{" "}
              </Link>
              <Link to="/training-days/step-9/#top" className={"stepNavigation__button--next"}>
                {" "}
                <span className={"stepNavigation__button--next--left"}> </span> Next{" "}
              </Link>
            </div>
            <p>Moving video about how Ramon y Cajal high school changed Dani´s life.</p>
          </aside>
        </section>
      </div>
    </LayoutStep>
  );
};

export default Step8;
